import {useEffect, useRef, useState, useCallback} from "react";
import objectFitImages from "object-fit-images";
import {List} from "immutable";
import {useDispatch} from "react-redux";

export function usePrevious(value, defaultValue = undefined) {
    const ref = useRef(defaultValue);

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export function useWindowSize() {
    const isClient = typeof window !== 'undefined';
    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined
        };
    }
    const [windowSize, setWindowSize] = useState(getSize);
    useEffect(() => {
        function handleResize() {
            setWindowSize(getSize());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
}

export function usePageMount() {
    useEffect(() => {
        objectFitImages();
    }, []);
}

export function useListTaskState() {
    const [state, setState] = useState({
        openMenu: false,
        menuAnchor: null,
        menuItems: List(),
        openAlertDialog: false,
        alertTitle: '',
        alertMessage: '',
        taskId: null,
    });

    return [state, setState];
}

export function useChangeInput(onChange) {
    const dispatch = useDispatch();

    return useCallback((e) => {
        dispatch(onChange(e.target.value));
    }, []);
}

export function useInfiniteScrolling(onIntersect, options = null) {
    const ref = useRef(null);

    const callback = useCallback(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    onIntersect(entry, observer);
                }
            })
        },
        [onIntersect]
    );

    useEffect(() => {
        if (!ref.current) {
            return;
        }
        const observer = new IntersectionObserver(callback, options);
        observer.observe(ref.current);

        return () => observer.disconnect();
    }, [options, callback])

    return ref;
}