import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "next-i18next";
import Head from "next/head";
import {getTitle} from "../../behaviors/SeoBehavior";
import {makeDefaultNs} from "../../utils/LocaleUtils";

function SeoManager({title, titleId}) {
    const defaultTitle = getTitle();
    const {t} = useTranslation(makeDefaultNs());
    let titleText = defaultTitle;

    if (title) {
        titleText = `${title} | ${defaultTitle}`;
    } else if (titleId) {
        titleText = `${t(titleId)} | ${defaultTitle}`;
    }

    return (
        <Head>
            <title>{titleText}</title>
            <meta property="og:title" content={titleText}/>
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
            <link
                href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&family=Noto+Sans+KR:wght@400;500;700&display=swap"
                rel="stylesheet"
            />
        </Head>
    )
}

SeoManager.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
};

export default SeoManager;
