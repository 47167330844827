import React from "react";
import PropTypes from "prop-types";
import {styled} from "@mui/material/styles";
import {FontStyle} from "../../styles/StyleMixin";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

const TypoLabel = styled(Typography)(({theme}) => ({
    ...FontStyle(theme, 14, 20, -0.7),
}));

const InputField = styled(TextField)(({theme}) => ({
    "& .MuiInput-input": {
        ...FontStyle(theme, 14, 20, -0.7),
        height: theme.spacing(20),
        padding: theme.spacing(13),
    },
}));

function InputBox(params) {
    const {
        className,
        error,
        label,
        id,
        type,
        variant,
        fullWidth,
        placeholder,
        value,
        helperText,
        endAdornment,
        onChange,
        onKeyPress,
        onKeyUp,
    } = params;

    return (
        <Box>
            {label && <TypoLabel>{label}</TypoLabel>}
            <InputField
                sx={Boolean(label) && {mt: theme => theme.spacing(8)}}
                autoComplete={"off"}
                InputProps={{classes: endAdornment}}
                error={error}
                id={id}
                type={type}
                variant={variant}
                fullWidth={fullWidth}
                placeholder={placeholder}
                value={value}
                helperText={helperText}
                onChange={onChange}
                onKeyPress={onKeyPress}
                onKeyUp={onKeyUp}
            />
        </Box>
    )
}

InputBox.propTypes = {
    className: PropTypes.string,
    error: PropTypes.bool,
    label: PropTypes.string,
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
    fullWidth: PropTypes.bool,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    helperText: PropTypes.string,
    endAdornment: PropTypes.node,
    onChange: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func,
    onKeyUp: PropTypes.func,
};

InputBox.defaultProps = {
    error: false,
    label: '',
    type: 'text',
    variant: 'standard',
    fullWidth: false,
    helperText: '',
};

export default InputBox;
