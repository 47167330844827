import {getManagerInfo, getShopStaffInfo} from "./SessionUtils";
import Router from "next/router";
import {ERROR_1104_CHANGE_PERSON_CREDENTIAL} from "../rest/ErrorCodes";

export function parseProps(req = null) {
    let userAgent = "";
    let managerInfo = getManagerInfo(req);
    let shopStaffInfo = getShopStaffInfo(req);

    if (req) {
        userAgent = req['headers']['user-agent'];
    } else if (typeof window !== 'undefined') {
        const pageProps = window.__NEXT_DATA__.props.pageProps;

        userAgent = pageProps.userAgent;
        managerInfo = pageProps.managerInfo;
        shopStaffInfo = pageProps.shopStaffInfo;
    }

    return {userAgent, managerInfo, shopStaffInfo};
}

export function makeRedirect(destination, permanent = false) {
    return {redirect: {destination, permanent}};
}

export function validateShopStaff(profile) {
    const errorProfile = profile.get('error');

    if (!errorProfile) {
        return true;
    }

    const isCredentialError = errorProfile.get('code') === ERROR_1104_CHANGE_PERSON_CREDENTIAL;

    if (typeof window !== 'undefined' && isCredentialError) {
        alert("Expired User Info.");
    }

    return false;
}


export function redirectPage(isServer, res, location) {
    if (isServer && res) {
        res.writeHead(302, {Location: location});
        res.end();
    } else {
        Router.replace(location);
    }
}
