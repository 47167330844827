import React from "react";
import PropTypes from "prop-types";
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";

const SpinnerDialog = styled(Dialog)(({theme}) => ({
    "& .MuiDialog-paperScrollPaper": {
        overflow: "hidden",
        zIndex: '10000000',
    },
}));

function Spinner({open}) {
    return (
        <SpinnerDialog open={open}>
            <CircularProgress sx={{margin: "2rem"}} size={50}/>
        </SpinnerDialog>
    )
}

Spinner.propTypes = {
    open: PropTypes.bool.isRequired,
};

Spinner.defaultProps = {
    open: false,
};

export default Spinner;
