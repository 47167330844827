export function getManagerInfo(req) {
    if (req && req.session) {
        const {manager_id, manager_auth_token} = req.session;

        return {
            managerId: manager_id || '',
            managerAuthToken: manager_auth_token || '',
        };
    } else {
        return {managerId: '', managerAuthToken: ''};
    }
}

export function isManagerInfoValid(managerInfo) {
    if (!managerInfo) {
        return false;
    }

    const {managerId, managerAuthToken} = managerInfo;

    return Boolean(managerId) && Boolean(managerAuthToken)
}

export function getShopStaffInfo(req) {
    if (req && req.session) {
        const {shop_staff_id, shop_staff_auth_token, shop_staff_shop_id} = req.session;

        return {
            shopStaffId: shop_staff_id || '',
            shopStaffAuthToken: shop_staff_auth_token || '',
            shopStaffShopId: shop_staff_shop_id || '',
        };
    } else {
        return {shopStaffId: '', shopStaffAuthToken: '', shopStaffShopId: ''};
    }
}

export function isShopStaffInfoValid(shopStaffInfo) {
    if (!shopStaffInfo) {
        return false;
    }

    const {shopStaffId, shopStaffAuthToken, shopStaffShopId} = shopStaffInfo;

    return Boolean(shopStaffId) && Boolean(shopStaffAuthToken) && Boolean(shopStaffShopId);
}
