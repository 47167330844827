export const PATH_ROOT = '/';
export const PATH_EN = '/en';
export const PATH_FROM_CMS = '1';

export const PATH_SHOP_STAFF = `/shop_staff`;

export const PATH_PRICING = '/pricing';

export const PATH_PRIVACY = '/privacy';
export const PATH_TERMS_OF_USE = '/terms_of_use';

export const PATH_SHOP_STAFF_AUTHORIZE = `${PATH_SHOP_STAFF}/authorize`;
export const PATH_SHOP_STAFF_SIGNUP = `${PATH_SHOP_STAFF}/signup`;
export const PATH_SHOP_STAFF_SIGNIN = `${PATH_SHOP_STAFF}/signin`;
export const PATH_SHOP_STAFF_SIGNOUT = `${PATH_SHOP_STAFF}/signout`;
export const PATH_SHOP_STAFF_ACCOUNT_SETTINGS = `${PATH_SHOP_STAFF}/account_settings`;

export const PATH_SHOP_STAFF_BRANDS = `/brands`;
export const PATH_SHOP_STAFF_BRAND_ADD = `/brands/add`;
export const PATH_SHOP_STAFF_BRAND_EDIT = `/brands/[brandId]/edit`;
export const getShopStaffBrandsEditPath = brandId => PATH_SHOP_STAFF_BRAND_EDIT.replace('[brandId]', brandId);

export const PATH_SHOP_STAFF_PRODUCTS = `/products`;
export const PATH_SHOP_STAFF_PRODUCT_ADD = `/products/add`;
export const PATH_SHOP_STAFF_PRODUCT_EDIT = `/products/[productId]/edit`;
export const getShopStaffProductsEditPath = productId => PATH_SHOP_STAFF_PRODUCT_EDIT.replace('[productId]', productId);
export const PATH_SHOP_STAFF_PRODUCT_THREE_VIEWER = `/products/[productId]/three_viewer`;
export const getShopStaffProductsThreeViewerPath = productId => PATH_SHOP_STAFF_PRODUCT_THREE_VIEWER.replace('[productId]', productId);
export const PATH_SHOP_STAFF_PRODUCT_AR_VIEWER = `/products/[productId]/ar_viewer`;
export const getShopStaffProductsArViewerPath = productId => PATH_SHOP_STAFF_PRODUCT_AR_VIEWER.replace('[productId]', productId);

export const PATH_SHOP_STAFF_WHATS_NEWS = '/whats_news';

export const PATH_SHOP_STAFF_PRODUCT_SIZES = `/product_sizes`;
export const PATH_SHOP_STAFF_PRODUCT_SIZE_ADD = `/product_sizes/add`;
export const PATH_SHOP_STAFF_PRODUCT_SIZE_EDIT = `/product_sizes/[productSizeId]/edit`;
export const getShopStaffProductSizesEditPath = productSizeId => PATH_SHOP_STAFF_PRODUCT_SIZE_EDIT.replace('[productSizeId]', productSizeId);

export const PATH_SHOP_STAFF_PRODUCT_COLORS = `/product_colors`;
export const PATH_SHOP_STAFF_PRODUCT_COLOR_ADD = `/product_colors/add`;
export const PATH_SHOP_STAFF_PRODUCT_COLOR_EDIT = `/product_colors/[productColorId]/edit`;
export const getShopStaffProductColorsEditPath = productColorId => PATH_SHOP_STAFF_PRODUCT_COLOR_EDIT.replace('[productColorId]', productColorId);

export const PATH_SHOP_STAFF_SHOP_INFO = `/shops/[shopId]`;
export const getShopStaffShopInfoPath = shopId => PATH_SHOP_STAFF_SHOP_INFO.replace('[shopId]', shopId);
export const PATH_SHOP_STAFF_SHOP_PLUGIN_SETTINGS = `/shops/[shopId]/plugin`;
export const getShopStaffShopPluginSettingsPath = shopId => PATH_SHOP_STAFF_SHOP_PLUGIN_SETTINGS.replace('[shopId]', shopId);
export const PATH_SHOP_STAFF_SHOP_PLUGIN_SKIN_FILTER_VIEWER = '/shops/[shopId]/plugin/skin_filter_viewer';
export const getShopStaffShopPluginSkinFilterViewerPath = shopId => PATH_SHOP_STAFF_SHOP_PLUGIN_SKIN_FILTER_VIEWER.replace('[shopId]', shopId);
export const PATH_SHOP_STAFF_SHOP_PAYMENT_HISTORIES = '/shops/[shopId]/payment_histories';
export const getShopStaffShopPricingPaymentHistoriesPath = shopId => PATH_SHOP_STAFF_SHOP_PAYMENT_HISTORIES.replace('[shopId]', shopId);

export const PATH_SHOP_STAFF_WORK_REQUEST = `/products/[productId]/workRequest`;
export const PATH_SHOP_STAFF_WORK_REQUEST_EDIT = `/products/[productId]/workRequest/[workRequestId]/edit`;
export const getShopStaffWorkRequestPath = (productId) => PATH_SHOP_STAFF_WORK_REQUEST.replace('[productId]', productId);
export const getShopStaffWorkRequestEditPath = (productId, workRequestId) => PATH_SHOP_STAFF_WORK_REQUEST_EDIT.replace('[productId]', productId).replace('[workRequestId]', workRequestId);


export const PATH_CAFE24_APP = '/cafe24/app';
export const PATH_CAFE24_INSTALLATION = '/cafe24/installation';

export const NEXT_INTERNAL_ROUTING_PATH = '/_next/data';


/* ADMIN */
export const PATH_ADMIN = '/admin';

export const PATH_ADMIN_AUTHORIZE = `${PATH_ADMIN}/authorize`;
export const PATH_ADMIN_SIGNIN = `${PATH_ADMIN}/signin`;
export const PATH_ADMIN_SIGNOUT = `${PATH_ADMIN}/signout`;

export const PATH_ADMIN_PRODUCTS = `${PATH_ADMIN}/products`;
export const PATH_ADMIN_PRODUCT_ADD = `${PATH_ADMIN}/products/add`;
export const PATH_ADMIN_PRODUCT_EDIT = `${PATH_ADMIN}/products/[productId]/edit`;
export const getAdminProductsEditPath = productId => PATH_ADMIN_PRODUCT_EDIT.replace('[productId]', productId);
export const PATH_ADMIN_PRODUCT_THREE_VIEWER = `${PATH_ADMIN}/products/[productId]/three_viewer`;
export const getAdminProductsThreeViewerPath = productId => PATH_ADMIN_PRODUCT_THREE_VIEWER.replace('[productId]', productId);
export const PATH_ADMIN_PRODUCT_AR_VIEWER = `${PATH_ADMIN}/products/[productId]/ar_viewer`;
export const getAdminProductsArViewerPath = productId => PATH_ADMIN_PRODUCT_AR_VIEWER.replace('[productId]', productId);
export const PATH_ADMIN_PAYMENT_HISTORIES = `${PATH_ADMIN}/payment_histories`;
export const PATH_ADMIN_SHOPS = `${PATH_ADMIN}/shops`;
export const PATH_ADMIN_SHOP_INFO = `${PATH_ADMIN}/shops/[shopId]`;
export const getAdminShopInfoPath = shopId => PATH_ADMIN_SHOP_INFO.replace('[shopId]', shopId);