export const FontStyle = (theme, fontSize, lineHeight, letterSpacing = null, fontWeight = 'normal') => {
    let style = {
        fontSize: theme.spacing(fontSize),
        lineHeight: theme.spacing(lineHeight),
        fontWeight,
    };

    if (letterSpacing) {
        style.letterSpacing = theme.spacing(letterSpacing);
    }

    return style;
};
