import {LARGE_SIZE, MEDIUM_SIZE, XLARGE_SIZE} from "./BreakPoint";
import {
    LARGE_HORZ_SPACING,
    MEDIUM_HORZ_SPACING,
    TOP_BAR_HEIGHT,
    TOP_BAR_HEIGHT_LARGE,
    XLARGE_HORZ_SPACING,
} from "../constants/CssConstants";

export const AdminRootLayout = (theme) => {
    return {
        "@media (min-width: 0px) and (orientation: landscape)": {
            paddingTop: "48px",
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: "64px",
        },
        paddingTop: "56px",
    };
};

export const AdminWrapper = (theme) => {
    return {
        [theme.breakpoints.up('md')]: {
        },
        [theme.breakpoints.up('lg')]: {
        },
        [theme.breakpoints.up('xl')]: {
            margin: `${theme.spacing(16)} auto`,
        },
        margin: theme.spacing(8),
    };
};

export const MaxWidthLayout = (theme, mq = {}, maxWidth = XLARGE_SIZE) => {
    return {
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(MEDIUM_HORZ_SPACING),
            paddingRight: theme.spacing(MEDIUM_HORZ_SPACING),
            ...mq[MEDIUM_SIZE],
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: theme.spacing(LARGE_HORZ_SPACING),
            paddingRight: theme.spacing(LARGE_HORZ_SPACING),
            ...mq[LARGE_SIZE],
        },
        [theme.breakpoints.up('xl')]: {
            paddingLeft: theme.spacing(XLARGE_HORZ_SPACING),
            paddingRight: theme.spacing(XLARGE_HORZ_SPACING),
            margin: `0 auto`,
            ...mq[XLARGE_SIZE],
        },
        maxWidth: theme.spacing(maxWidth || XLARGE_SIZE),
    };
};

export const AdjustTopBar = (theme, mq = {}) => {
    return {
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(TOP_BAR_HEIGHT_LARGE),
            ...mq[MEDIUM_SIZE],
        },
        [theme.breakpoints.up('lg')]: {
            ...mq[LARGE_SIZE],
        },
        [theme.breakpoints.up('xl')]: {
            ...mq[XLARGE_SIZE],
        },
        paddingTop: theme.spacing(TOP_BAR_HEIGHT),
    };
};

export const SingleEllipsis = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
};

export const MultilineEllipsis = (lineCount) => {
    return {
        display: "-webkit-box",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "pre-wrap",
        "-webkit-line-clamp": lineCount,
        "-webkit-box-orient": "vertical"
    };
};

export const ObjectFit = (value) => {
    return {
        objectFit: value,
        fontFamily: `'object-fit: ${value};'`
    };
};

export const ImageRatioBox = (theme, ratio = "100%") => {
    return {
        "&:after": {
            content: "''",
            display: "block",
            paddingBottom: ratio || "100%",
        },
        "& > img": {
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
        },
        position: "relative",
    };
};

export const SquareImageWrapper = {
    position: "relative",
    "&:after": {
        content: "''",
        display: "block",
        paddingBottom: "100%",
    },
};

export const SquareImage = {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
};

export const MakeBtnHoverActive = (hoverColor, activeColor) => {
    return {
        "&:hover": {
            backgroundColor: hoverColor,
        },
        "&:active": {
            backgroundColor: activeColor,
        },
    }
}
